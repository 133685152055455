import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../views/Template/Auth";
import Maqueta from "../views/Template/Maqueta";
import MaquetaManager from "../views/Template/MaquetaAdmin";
import maquetaCreate from "../views/Template/maquetaCreate";
import maquetaApp from "../views/Template/App";
import store from "../store/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/:provider/callback",
    component: {
      template: '<div class="auth-component"></div>',
    },
  },
  {
    path: "/verific/token/account/:token",
    name: "Confirmar",
    props: true,
    component: () => import("@/views/Template/Auth/Confirmar.vue"),
  },

  {
    path: "/",
    component: Auth,

    children: [
      {
        path: "/",
        name: "Login",
        component: () => import("@/views/Template/Auth/Login.vue"),
      },
      {
        path: "/register",
        name: "Register",
        component: () => import("@/views/Template/Auth/Register.vue"),
      },
      {
        path: "/reset",
        name: "Reset",
        component: () => import("@/views/Template/Auth/Reset.vue"),
      },

      {
        path: "/recuperar/:token",
        name: "confirmacionReset",
        props: true,
        component: () =>
          import("@/views/Template/Auth/confirmationRecovery.vue"),
        meta: {
          requiresAuth: true,
          roll: "empresa",
        },
      },
    ],
  },

  {
    // =============================================================================
    // Panel Business
    // =============================================================================
    path: "/business/",
    component: Maqueta,
    children: [
      {
        path: "profile",
        name: "Perfil",
        component: () => import("@/views/miCuentaScreen/index.vue"),
        children: [
          {
            path: "config-account",
            name: "configAccount",
            component: () =>
              import("@/components/miCuenta/configuracionCuenta.vue"),
          },
          {
            path: "config-language",
            name: "configLanguage",
            component: () =>
              import("@/components/miCuenta/configuracionIdioma.vue"),
          },

          {
            path: "secure-sesion",
            name: "secureSesion",
            component: () =>
              import("@/components/miCuenta/seguridadCuenta.vue"),
          },
          {
            path: "billing",
            name: "Billing",
            component: () =>
              import("@/components/miCuenta/facturacionCuenta.vue"),
          },
          {
            path: "account-invoices",
            name: "InvoicesMenu",
            component: () =>
              import("@/components/miCuenta/facturaSuscripcion.vue"),
          },
          {
            path: "account-waba",
            name: "wabaAccount",
            component: () => import("@/views/Business/WabaIntegration.vue"),
          },
          {
            path: "waba-integration",
            name: "wabaIntegration",
            component: () => import("@/views/Business/menuIntegration.vue"),
          },
          {
            path: "integrations",
            name: "Integrations",
            component: () => import("@/views/Business/Integraciones/index.vue"),
          },
        ],
      },
      {
        path: "whatsapp-mass-communication",
        name: "indexWhatsappCommunication",
        component: () => import("@/views/Business/massiveWhatsApp/index.vue"),
      },
      {
        path: "notificaciones",
        name: "notificacionesUser",
        component: () => import("@/views/Business/Notificaciones/index.vue"),
      },
      {
        path: "checkin-management",
        name: "Bookings",
        component: () => import("@/views/Business/Bookings/index.vue"),
      },
      {
        path: "scheduled-checkins",
        name: "Reservas",
        component: () => import("@/views/Business/Reservas/index.vue"),
      },
      {
        path: "scheduled-checkins/bell",
        name: "configuracionReservas",
        component: () =>
          import("@/views/Business/Reservas/configuracionReserva.vue"),
      },
      {
        path: "scheduled-checkins/import",
        name: "configuracionImport",
        component: () =>
          import("@/views/Business/Reservas/configuracionReservaImport.vue"),
      },

      {
        path: "scheduled-checkins/:id/:reserva/reserva",
        name: "ReservasID",
        props: true,
        component: () => import("@/views/Business/Reservas/viewReserve.vue"),
      },

      {
        path: "waba_integration",
        name: "wabaIntegration",
        component: () => import("@/views/Business/WabaIntegration.vue"),
      },

      {
        path: "waba_integration/embedded-signup",
        name: "wabaIntegrationSignup",
        component: () => import("@/views/Business/menuIntegration.vue"),
      },

      {
        path: "properties",
        name: "Propiedades",
        component: () => import("@/views/Business/Propiedades/index.vue"),
      },

      {
        path: "properties/edition/:propiedad_id",
        name: "editionProperty",
        props: true,
        component: () =>
          import("@/views/Business/Propiedades/contenidoView.vue"),
        children: [
          {
            path: "rooms",
            name: "Habitaciones",
            props: true,
            component: () =>
              import("@/components/Propiedades/Habitaciones.vue"),
          },
          {
            path: "authorities",
            name: "Authorities",
            props: true,
            component: () => import("@/components/Propiedades/Autoridades.vue"),
          },
          {
            path: "notifications",
            name: "Notifications",
            props: true,
            component: () =>
              import("@/components/Propiedades/Notificaciones.vue"),
          },
          {
            path: "qr-property",
            name: "QRPropiedad",
            props: true,
            component: () => import("@/components/Propiedades/QR.vue"),
          },
          {
            path: "seguridad-property",
            name: "SeguridadPropiedad",
            props: true,
            component: () => import("@/components/Propiedades/Seguridad.vue"),
          },
          {
            path: "identity-verification",
            name: "identityVerification",
            props: true,
            component: () =>
              import("@/components/Propiedades/VerificarIdentidad.vue"),
          },

          {
            path: "payments",
            name: "Payments",
            props: true,
            component: () => import("@/components/Propiedades/Payments.vue"),
          },

          {
            path: "guest-reviews",
            name: "Review",
            props: true,
            component: () =>
              import("@/components/Propiedades/valoracionHuespedes.vue"),
          },
          {
            path: "sync-calendar",
            name: "syncCalendar",
            props: true,
            component: () =>
              import("@/components/Propiedades/syncCalendar/index.vue"),
          },


          {
            path: "data-basic-accommodation",
            name: "ImageAccommodation",
            props: true,
            component: () =>
              import("@/components/Propiedades/imageAccommodation.vue"),
          },

          {
            path: "accommodation-policies",
            name: "accommodationPolicies",
            props: true,
            component: () =>
              import("@/components/Propiedades/accommodationPolicies.vue"),
          },

          {
            path: "welcome-accommodation",
            name: "welcomeAccommodation",
            props: true,
            component: () =>
              import("@/components/Propiedades/welcomeAccommodation.vue"),
          },

          {
            path: "region-accommodation",
            name: "regionAccommodation",
            props: true,
            component: () =>
              import("@/components/Propiedades/regionAccommodation.vue"),
          },

          {
            path: "page-chat-bot-ia",
            name: "pageManageChatBot",
            props: true,
            component: () => import("@/views/Business/chatBot/pageIndex.vue"),
          }, 

        ],
      },

      {
        path: "/config_properties_notifications/:id/properties",
        name: "configUpselling",
        props: true,
        component: () =>
          import("@/views/Business/Propiedades/configNotifications.vue"),
      },

      {
        path: "service",
        name: "Servicios",
        component: () => import("@/views/Business/Upselling/Servicios.vue"),
      },

      {
        path: "invoices",
        name: "Invoices",
        component: () => import("@/views/Business/Facturas/index.vue"),
        children: [
          {
            path: "/select/suscriptions",
            name: "InvoicesCompra",
            component: () =>
              import("@/views/Business/Facturas/SuscripcionesCompra.vue"),
          },
          {
            path: "/manage/update-suscription",
            name: "updateSubscription",
            component: () =>
              import("@/views/Business/Facturas/updateSubscription.vue"),
          },

          {
            path: "invoicelist",
            name: "Invoicelist",
            component: () => import("@/views/Business/Facturas/Facturas.vue"),
          },
        ],
      },
      {
        path: "upselling/",
        name: "Upselling",
        component: () => import("@/views/Business/Upselling/index.vue"),
        children: [
          {
            path: "offer",
            name: "Ofertas",
            component: () => import("@/views/Business/Upselling/Ofertas.vue"),
          },
          {
            path: "providers",
            name: "Proveedores",
            component: () =>
              import("@/views/Business/Upselling/Proveedores.vue"),
          },
          {
            path: "reports",
            name: "Informes",
            component: () => import("@/views/Business/Upselling/Informes.vue"),
          },
        ],
      },
    ],
    meta: {
      requiresAuth: true,
      roll: "empresa",
    },
  },

  {
    // =============================================================================
    // Panel Business Creacion y edicion
    // =============================================================================
    path: "/business/",
    component: maquetaCreate,
    children: [
      {
        path: "create/first/properties",
        name: "firstCreatePropiedad",
        component: () =>
          import("@/views/Business/Propiedades/firstCreatePropiedad.vue"),
      },
      {
        path: "create/properties",
        name: "createPropiedad",
        component: () =>
          import("@/views/Business/Propiedades/createAccommodation.vue"),
      },
      {
        path: "update/:id/properties",
        name: "updatePropiedad",
        props: true,
        component: () =>
          import("@/views/Business/Propiedades/updatePropiedad.vue"),
      },
      {
        path: "create/services",
        name: "createServicio",
        component: () =>
          import("@/views/Business/Upselling/createServicio.vue"),
      },
      {
        path: "update/:id/service",
        name: "updateServicio",
        props: true,
        component: () =>
          import("@/views/Business/Upselling/updateServicio.vue"),
      },
      {
        path: "checkin/service/manager/system",
        name: "checkinManual",
        props: true,
        component: () => import("@/views/Business/Checkin/index.vue"),
      },
      {
        path: "checkin/service/previe/link/system",
        name: "checkinPrevieLink",
        props: true,
        component: () => import("@/views/Business/Checkin/previeCheckin"),
      },
      {
        path: "checkin/service/previe/system",
        name: "checkinPrevie",
        props: true,
        component: () => import("@/views/Business/Checkin/previeCheckin"),
      },
      // Ruta inicial del bot 
      
      {
        path: "accommodation/manage-chat-bot-ia",
        name: "manageChatBot",
        props: true,
        component: () => import("@/views/Business/chatBot/index.vue"),
      },
      
    ],
    meta: {
      requiresAuth: true,
      roll: "empresa",
    },
  },

  {
    // =============================================================================
    // Panel Manager
    // =============================================================================
    path: "/manager/",
    component: MaquetaManager,
    children: [
      {
        path: "",
        name: "HomeManager",
        component: () => import("@/views/Manager/index.vue"),
      },
      {
        path: "users",
        name: "UsuariosManager",
        component: () => import("@/views/Manager/users/index.vue"),
      },
      {
        path: "data-information-:user_id-user/",
        name: "adminUserData",
        component: () => import("@/views/Manager/users/viewDataUser.vue"),
        props: true,
      },
      {
        path: "accommodation-:accommodation_id-manager-user-:user_id-information",
        component: () => import("@/views/Manager/users/viewContentData.vue"),
        props: true,
        children: [
          {
            path: "/",
            name: "viewAccomodationManager",
            component: () =>
              import("@/views/Manager/users/viewAccommodationUser.vue"),
            props: true,
          },
          {
            path: "retrive-checkins",
            name: "viewAccomodationManagerCheckins",
            component: () =>
              import("@/views/Manager/users/viewAccommodationCheckins.vue"),
            props: true,
          },
          {
            path: "retrive-checkins-pending",
            name: "viewAccomodationManagerCheckinsPending",
            component: () =>
              import(
                "@/views/Manager/users/viewAccommodationCheckinsPending.vue"
              ),
            props: true,
          },
        ],
      },

      {
        path: "advisors",
        name: "advisorsPage",
        component: () => import("@/views/Manager/advisors/index.vue"),
      },

      {
        path: "prices",
        name: "PricesManager",
        component: () => import("@/views/Manager/prices/index.vue"),
      },
      {
        path: "payments",
        name: "PagosManager",
        component: () => import("@/views/Manager/pagos/index.vue"),
      },
      {
        path: "user_management",
        name: "GestionManager",
        component: () => import("@/views/Manager/admins/index.vue"),
      },
    ],
    meta: {
      requiresAuth: true,
      roll: "admin",
    },
  },

  {
    path: "/test",
    name: "vistapdf",
    props: true,
    component: () => import("@/views/Business/examples/pdf.vue"),
  },

  {
    path: "/example/canvas",
    name: "CanvasExample",
    props: true,
    component: () => import("@/views/App/example.vue"),
  },

  {
    path: "/logout",
    name: "Logout",
    props: true,
    component: () => import("@/views/Logout.vue"),
  },

  // App rutas
  {
    path: "/checkin/customers/previe/:propiedad_id/",
    props: true,
    component: maquetaApp,
    children: [
      {
        path: "property",
        name: "WelcomeCheckin",
        props: true,
        component: () => import("@/views/App/welcome.vue"),
      },
      {
        path: "app/",
        name: "checkinPrevieLink",
        props: true,
        component: () => import("@/views/App/index.vue"),
      },
      {
        path: "demo/",
        name: "checkinPrevieLinkDemo",
        props: true,
        component: () => import("@/views/App/index.vue"),
      },
    ],
  },

  {
    path: "/checkin/customers/:propiedad_id/",
    props: true,
    component: maquetaApp,
    children: [
      {
        path: "property",
        name: "WelcomeCheckin",
        props: true,
        component: () => import("@/views/App/welcome.vue"),
      },
      {
        path: "app",
        name: "Checkin",
        props: true,
        component: () => import("@/views/App/index.vue"),
      },

      {
        path: "/services/:propiedad_id/:checkin_id/property",
        name: "ServiceProperty",
        props: true,
        component: () => import("@/views/App/Services/index.vue"),
      },
      {
        path: "/services/:propiedad_id/:service_id/:checkin_id/vew/",
        name: "seviceView",
        props: true,
        component: () => import("@/views/App/Services/viewService.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("soy navegacion router");
  console.log(to.matched.some((record) => record.meta.requiresAuth));
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      console.log("requiresAuth");
      next({ name: "Login" });
    } else {
      console.log("Validacion ruta");
      var TypeUser = to.matched.some(
        (record) => record.meta.roll == store.state.user.type
      );
      console.log(store.state.user.type, "userType");
      console.log(
        to.matched.some((record) => record.meta.roll == store.state.user.type),
        "meta"
      );
      if (!TypeUser) {
        console.log("Entro en el typeUser")
        if (
          store.state.user.type == "admin" &&
          store.state.user.aplication == "wacheckin"
        ) {
          console.log("Ruta incorrecta");
          next({ name: "HomeManager" });
        }
        if (store.state.user.type == "empresa") {
          console.log("Ruta incorrecta");
          next({ name: "Bookings" });
        }
      } else {
        console.log("Ruta correcta");
        next();
      }
    }
  } else {
    console.log("NO requiresAuth");
    next(); // make sure to always call next()!
  }
});

export default router;
